import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import { get, findIndex } from 'lodash'

import PageLayout from '../components/page-layout'
import PageSections from '../components/page-sections'
import { getSEOFromPage } from '../components/seo'

import breakpoints from '../lib/constants/breakpoints.json'
import {
  formatAcfImageForARYImage,
  formatWpImageForARYImage,
} from '../lib/_helpers'

import { openOverlay } from '../redux/actions'

import iIntro from '../assets/imgs/home/intro_info.png'
import iIntroMob from '../assets/imgs/home/intro_info_mobile.png'

class PageInfo extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data, screenSize, location, fadeWait } = this.props

    const bgColour = 'green'

    return (
      <PageLayout
        seo={getSEOFromPage(get(data, 'page'))}
        location={location}
        pageIntroTitle="Info"
        pageIntroImage={{
          src: iIntro,
          width: 2560,
          height: 1340,
        }}
        pageIntroImageMobile={{
          src: iIntroMob,
          width: 1080,
          height: 1500,
        }}
        pageIntroColour={bgColour}
        showFooter={true}
      >
        <PageSections
          bgColour={bgColour}
          fadeWait={fadeWait}
          sections={get(data, 'page.acf.sections_page')}
        />
      </PageLayout>
    )
  }
}

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    overlay: ui.overlay,
    screenSize: ui.screenSize,
  }
}

export default connect(mapStateToProps)(PageInfo)

export const query = graphql`
  query($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      title
      slug
      path
      wordpress_id
      seo_meta {
        title
        description
      }
      acf {
        sections_page {
          __typename
          ... on WordPressAcf_text {
            el_id
            title
            description
          }
        }
      }
    }
  }
`
