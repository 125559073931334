import React from 'react'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { isFunction } from 'lodash'

import Details from '../details'
import { FAQSection } from '../faqs'
import Journey from '../journey'
import { SectionText } from '../section'

class PageSections extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { bgColour, fadeWait, sections } = this.props

    return sections
      ? sections.map((_section, _i) => {
          let Component = null
          let props = {}

          switch (_section.__typename) {
            case 'WordPressAcf_text':
              Component = SectionText
              props = Object.assign({}, props, {
                elID: _section.el_id,
                title: _section.title,
                body: _section.description,
                bgColour: bgColour,
                headerBgColour: true,
                fadeWait: fadeWait,
                showIndicator: _i === 0,
              })
              break
            case 'WordPressAcf_journey':
              Component = Journey
              props = Object.assign({}, props, {
                bgColour: bgColour,
              })
              break
            case 'WordPressAcf_details':
              Component = Details
              props = Object.assign({}, props, {
                bgColour: bgColour,
              })
              break
            case 'WordPressAcf_faqs':
              Component = FAQSection
              props = Object.assign({}, props, {
                bgColour: bgColour,
              })
            default:
              break
          }

          return Component ? <Component key={_i} {...props} /> : null
        })
      : null
  }
}

export default PageSections
