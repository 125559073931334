import React                from 'react'
import Select 				from 'react-select'
import {
	isFunction
} from 'lodash'

export default (props) => {
	const {
		options,
		value,
		onChange,
		placeholder
	} = props	

	return <div className="khDropdown">
		<Select
			classNamePrefix="kh-select"
			options={ options }
			placeholder={ placeholder }
			value={ value }
			isClearable={ true }
			isSearchable={ true }
			onChange={ (_item) => {
				if(onChange && isFunction(onChange)){
					onChange(_item)
				}
			} }
			/>
	</div>
}