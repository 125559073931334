import React 			    from 'react'
import classnames           from 'classnames'
import { connect }          from 'react-redux'
import {
	includes,
	filter,
	isFunction,
	get
} from 'lodash'
import {
	graphql,
	StaticQuery
} from 'gatsby'

import {
	formatTitle,
	renderHTML
} from '../../lib/_helpers'

import {
	openOverlay
} from '../../redux/actions'


const FAQQuestion = connect()((props) => {
	const {
		dispatch,
		open,
		title,
		acf,
		wordpress_id,
		onToggle,
		bgColour
	} = props

	return <div
		className={ classnames('faqQuestion', {
			'faqQuestion--open': !!open,
			[`bg--${ bgColour }`]: !!bgColour
		}) }>
		<div className="faqQuestion-question faqQuestion-item"
			onClick={ (e) => {
				e.preventDefault()
				e.stopPropagation()

				if(acf.answer && onToggle && isFunction(onToggle)){
					onToggle(wordpress_id, open ? false : true)
				}
			} }>
			<div
				className="faqQuestion-item-wrap">
				<div className="faqQuestion-label">
					<div className="textBlock">
						<p>Q.</p>
					</div>
				</div>
				<div className="faqQuestion-text">
					<div className="textBlock">
						{ title ? <p>{ renderHTML(title) }</p> : null }
					</div>
				</div>
			</div>
			<div className="faqQuestion-question-toggle" />
		</div>
		<div className="faqQuestion-answer faqQuestion-item">
			<div className="faqQuestion-item-wrap">
				<div className="faqQuestion-label">
					<div className="textBlock">
						<p>A.</p>
					</div>
				</div>
				<div className="faqQuestion-text">
					{ acf.answer || acf.further_reading ? <div className="textBlock">
						{ acf.answer ? renderHTML(acf.answer) : null }
						{ acf.further_reading ? <div className="faqQuestion-text-further">
							<p><strong>Further reading: </strong></p>
							{ acf.further_reading.map((_detail, _i) => {
								let _detailContent = null

								switch(_detail.type){
									case 'detail':
										if(_detail.detail){
											_detailContent = <p><a
												href="#"
												onClick={ (e) => {
													e.preventDefault()
													e.stopPropagation()

													dispatch(openOverlay({
														type: 'detail',
														id: _detail.detail.wordpress_id
													}))
												} }
												>
												{ _detail.detail.post_title }
											</a></p>
										}
										break
									case 'text':
										_detailContent = renderHTML(_detail.text)
										break
									default:
										break
								}

								if(!_detailContent){ return null }

								return <React.Fragment
									key={ _i }>
									{ _detailContent }
								</React.Fragment>
							}) }
						</div> : null }
					</div> : null }
				</div>
			</div>
		</div>
	</div>
})

class FAQs extends React.Component {
	constructor(props) {
		super(props)

		this.onToggle = this.onToggle.bind(this)

		this.state = {
			openID: null
		}
	}

	componentDidUpdate(prevProps){
		const prevFilter = get(prevProps, 'faqFilter.value')
		const newFilter = get(this.props, 'faqFilter.value')

		if(prevFilter !== newFilter){
			this.setState({
				openID: null
			})
		}
	}

	onToggle(id, bool){
		this.setState({
			openID: bool ? id : null
		})
	}

	render() {
		const {
			openID
		} = this.state
		const {
			faqFilter,
			data,
			bgColour
		} = this.props

		const faqs = get(data, 'faqs.edges')
		const _filtered = faqFilter ? filter(faqs, (_o, _i) => {
				return includes(_o.node.searchterms, faqFilter.value)
			}): faqs

		return <div
			className={ classnames('faqs') }>
			{ _filtered && _filtered.length > 0 ? _filtered.map((_question, _i) => {
				return <FAQQuestion
					key={ _i }
					{ ..._question.node }
					open={ _question.node.wordpress_id === openID }
					bgColour={ bgColour }
					onToggle={ this.onToggle } />
			}) : null }
		</div>
	}
}

export default (props) => {
	return <StaticQuery
		query={ graphql`
			query {
				faqs: allWordpressWpFaq {
					edges {
						node {
							wordpress_id
							title
							acf {
								answer
								further_reading {
									type
									text
									detail {
										wordpress_id
										post_title
									}
								}
							}
							searchterms
						}
					}
				}
			}
		` }
		render={ (data) => {
			return <FAQs
				data={ data }
				{ ...props } />
		} } />
}