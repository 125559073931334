import React 			    from 'react'
import classnames           from 'classnames'
import { connect }          from 'react-redux'
import { withContentRect }  from 'react-measure'
import {
    get
} from 'lodash'
import {
    StaticQuery,
    graphql
} from 'gatsby'

import FAQs                 from './faqs'

import Dropdown             from '../dropdown'
import FixedScrollEl        from '../fixed-scroll-el'
import SectionWrapper       from '../section/wrapper'

import breakpoints from '../../lib/constants/breakpoints.json'
import {
    scrollToID
} from '../../lib/scrollTo'

const FAQSectionFilterCol = withContentRect('bounds')((props) => {
    const {
        measureRef,
        contentRect,
        fixedStyle,
        containerHeight,
        options,
        setFilter,
        filter
    } = props

    const _style = {
        ...fixedStyle,
        width: contentRect.bounds.width,
        height: containerHeight
    }
    return <div
        ref={ measureRef }
        className="faqSection-col faqSection-col--margin nav-margin">
        { options && options.length > 0 ? <div className="faqSection-dropdown"
            style={ _style }>
                <Dropdown
                    placeholder="Filter by"
                    onChange={ (item) => {
                        setFilter(item)
                    } }
                    value={ filter }
                    options={ options } />
            </div> : null }
    </div>
})

class FAQSection extends React.Component {
    constructor(props) {
        super(props)

        this.setFilter = this.setFilter.bind(this)

        this.state = {
            faqFilter: null
        }
    }

    setFilter(item){
        const {
            offset
        } = this.props

        this.setState({
            faqFilter: item
        })

        scrollToID('faqAnswers', 900, offset - 1)
    }

    render() {
        const {
            faqFilter
        } = this.state
        const {
            bgColour,
            options,
            fixedStyle,
            screenSize,
            offset
        } = this.props

        const isTablet = screenSize.width <= breakpoints.tablet
        const isMobile = screenSize.width <= breakpoints.mobile

        const FAQFilter = <FAQSectionFilterCol
            options={ options }
            filter={ faqFilter }
            setFilter={ this.setFilter }
            fixedStyle={ isMobile ? null : fixedStyle }
            containerHeight={ isMobile ? null : screenSize.height - (offset + 2) } />

        return <SectionWrapper
            bgColour={ bgColour }
            headerBgColour={ true }
            elID="faqAnswers"
            className="faqSection">
            { isTablet ? null : <div className="faqSection-col faqSection-col--margin nav-margin" /> }
            <div className="faqSection-col faqSection-col--content">
                { isMobile ? FAQFilter : null }
                <FAQs
                    bgColour={ bgColour }
                    faqFilter={ faqFilter } />
            </div>
            { isMobile ? null : FAQFilter }
        </SectionWrapper>
    }
}

const mapStateToProps = (state) => {
    return {
        screenSize: state.ui.screenSize,
        headerHeight: state.ui.header.height
    }
}

const FixedFAQSection = FixedScrollEl(connect(mapStateToProps, null)(FAQSection))

export default (props) => {
    return <StaticQuery
        query={ graphql`
            query {
                searchterms: allWordpressWpSearchterms {
                    edges {
                        node {
                            wordpress_id
                            name
                        }
                    }
                }
            }
        ` }
        render={ (data) => {
            const searchTerms = get(data, 'searchterms.edges')
            const searchTermOptions = searchTerms && searchTerms.length > 0 ? searchTerms.map((_term, _i) => {
                return {
                    value: _term.node.wordpress_id,
                    label: _term.node.name
                }
            }) : null

            return <FixedFAQSection
                data={ data }
                options={ searchTermOptions }
                { ...props } />
        } } />
}
