import React 			    from 'react'
import ReactDOM             from 'react-dom'
import classnames           from 'classnames'
import { connect }          from 'react-redux'
import getNodeDimensions    from 'get-node-dimensions'
import {
    isFinite,
    get,
    times,
    meanBy
} from 'lodash'
import {
    StaticQuery,
    graphql
} from 'gatsby'
import PS                   from 'publish-subscribe-js'

import Breadcrumbs 			from '../breadcrumbs'
import {
	ImageGallery
} from '../image-gallery'
import InlineImage         from '../inline-image'
import FixedScrollEl        from '../fixed-scroll-el'
import SectionWrapper       from '../section/wrapper'
import SplitScroll          from '../split-scroll'
import Toggle  				from '../toggle'

import breakpoints         from '../../lib/constants/breakpoints.json'
import {
    formatTitle,
	bgColourIsDark,
    formatImageForARYImage,
    renderHTML
} from '../../lib/_helpers'
import {
    InlineImageFragment
} from '../../lib/fragments'
import {
    scrollToID
} from '../../lib/scrollTo'
import {
    RESIZE,
    SCROLL
} from '../../lib/pubSubEvents'

class JourneyStep extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return null
    }    
}

class Journey extends React.Component {
    constructor(props) {
        super(props)

        this.els = []
        this.checkScrollUpdate = this.checkScrollUpdate.bind(this)

        this.state = {
        	activeIndex: 0
        }
    }

    componentDidMount(){
        this.resizeKey = PS.subscribe(RESIZE, this.checkScrollUpdate)
        this.scrollKey = PS.subscribe(SCROLL, this.checkScrollUpdate)

        this.checkScrollUpdate()
    }

    componentWillUnmount() {
        if(this.resizeKey){ PS.unsubscribe(RESIZE, this.resizeKey) }
        if(this.scrollKey){ PS.unsubscribe(SCROLL, this.scrollKey) }
    }

    checkScrollUpdate(){
        const {
            activeIndex
        } = this.state
        const {
            screenSize
        } = this.props
        
        let maxIndex = 0
        const offset = screenSize.height * 0.5
        for(let i = 0; i < this.els.length; i++){
            const _el = this.els[i]
            if(!_el){ continue }
            const _elEl = ReactDOM.findDOMNode(_el)
            const _elDimensions = getNodeDimensions(_elEl)

            if(_elDimensions.top <= offset){
                maxIndex = Math.max(maxIndex, i)
            }
        }

        if(maxIndex !== activeIndex){
            this.setState({
                activeIndex: maxIndex
            })
        }
    }

    journeyStepID(index){
        return `journeyStep_${ index }`
    }

    render() {
    	const {
    		activeIndex
    	} = this.state
        const {
            bgColour,
            data,
            screenSize,
            headerHeight,
            fixedStyle
        } = this.props

        const isMobile = screenSize.width <= breakpoints.mobile

        const steps = get(data, 'journeysteps.edges')

        if(!steps || steps.length <= 0){ return null }
        
        let footerStyle = {
            ...fixedStyle
        }
        if(footerStyle.position === 'fixed'){
            footerStyle.top = screenSize.height - headerHeight
        } else if(footerStyle.position === 'absolute' && footerStyle.top !== 'auto'){
            footerStyle.top = screenSize.height - (headerHeight * 2)
        }

        return <SectionWrapper
            bgColour="offWhite"
            headerBgColour={ true }
            className="journeySection">
            { steps.map((_step, _i) => {
                const _title = get(_step, 'node.title')
                const _description = get(_step, 'node.acf.description')
                const _imageObj = formatImageForARYImage(get(_step, 'node.acf.image'))

                const _imageEl = <div className="journeySection-step-image">
                    { _imageObj ? <InlineImage
                        image={ _imageObj } /> : null }
                </div>

                const _contentEl = <div className="journeySection-step-content">
                    <div className="cont cont--half cont--half-right">
                        <div className="textBlock">
                            { _title ? <h3>{ _i + 1 }. { formatTitle(_title) }</h3> : null }
                            { _description ? renderHTML(_description) : null }
                        </div>
                    </div>
                </div>

                return <div
                    key={ _i }
                    className={ classnames('journeySection-step', {
                        'journeySection-step--mobile': isMobile
                    }) }
                    ref={ (el) => { 
                        this.els[_i] = el 
                    } }
                    id={ this.journeyStepID(_i) }>
                    { isMobile ? <React.Fragment>
                            { _imageEl }
                            { _contentEl }
                        </React.Fragment> : <SplitScroll
                            trackToBottom={ true }
                            leftEl={ _imageEl }
                            rightEl={ _contentEl } /> }
                </div>
            }) }
            { isMobile ? null : <React.Fragment>
                <div className="journeySection-footerSpacer" />
                <div className="journeySection-footer"
                    style={ footerStyle }>
                	<div className="journeySection-footer-label">A simple { steps.length } step process</div>
                	<Breadcrumbs
                		activeIndex={ activeIndex }
                		length={ steps.length }
                		dark={ true }
                		noFade={ true }
                		crumbContent={ times(steps.length, String).map((_o, _i) => {
                			return parseInt(_i) + 1
                		}) }
                		onCrumbSelect={ (index) => {
                            scrollToID(this.journeyStepID(index), 900, headerHeight - 1)
                		} } />
                </div>
            </React.Fragment> }
        </SectionWrapper>
    }
}

const mapStateToProps = (state) => {
    const {
        ui
    } = state

    return {
        headerHeight: ui.header.height,
        screenSize: ui.screenSize
    }
}

const ReduxJourney = FixedScrollEl(connect(mapStateToProps, null)(Journey))

export default (props) => {
    return <StaticQuery
        query={ graphql`
            query {
                journeysteps: allWordpressWpJourney {
                    edges {
                        node {
                            wordpress_id
                            title
                            acf {
                                image {
                                    ...ARYImageInline
                                }
                                description
                            }
                        }
                    }
                }
            }
        ` }
        render={ (data) => {
            return <ReduxJourney
                data={ data }
                { ...props } />
        } } />
}
