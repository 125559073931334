import React 			    from 'react'
import { connect }          from 'react-redux'
import {
    each,
    isFunction,
    get
} from 'lodash'
import {
    StaticQuery,
    graphql
} from 'gatsby'

import InlineImage         from '../inline-image'
import {
    FixedTextEl,
    ScrollTextEl
} from '../section/text-el'
import SectionWrapper       from '../section/wrapper'

import {
    openOverlay
} from '../../redux/actions'

import {
    formatTitle,
    formatImageForARYImage
} from '../../lib/_helpers'
import {
    ratioFromImage,
    roundTo
} from '../../lib/mathHelpers'
import {
    ThumbnailImageFragment
} from '../../lib/fragments'

const DetailSquare = (props) => {
    const {
        id,
        title,
        image,
        imageRatio,
        onClick
    } = props

    return <div
        className="detailsSection-grid-item"
        onClick={ id ? (e) => {
            e.preventDefault()
            e.stopPropagation()

            if(onClick && isFunction(onClick)){
                onClick(id)
            }
        } : null }>
        <div
            className="detailsSection-grid-square">
            <div className="detailsSection-grid-square-content">
                { title ? <h3 className="detailsSection-grid-square-title">{ formatTitle(title) }</h3> : null }
                { image ? <div className="detailsSection-grid-square-image">
                    <div
                        className="detailsSection-grid-square-image-spacer"
                        style={{
                            paddingBottom: `${ imageRatio * 100 }%`
                        }}
                        />
                    <InlineImage
                        contain={ true }
                        image={ image } />
                </div> : null }
            </div>
        </div>
    </div>
}

class Details extends React.Component {
    constructor(props) {
        super(props)

        this.onDetailClick = this.onDetailClick.bind(this)

        this.state = {
            maxIconRatio: this.maxIconRatio(props)
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.details && this.props.details && prevProps.details.length !== this.props.details.length){
            this.setState({
                maxIconRatio: this.maxIconRatio()
            })
        }
    }

    maxIconRatio(props = null){
        const _props = props ? props : this.props
        const {
            details
        } = _props

        let maxRatio = 0
        each(details, (_detail, _i) => {
            const _image = formatImageForARYImage(get(_detail, 'node.acf.thumbnail_image'))
            if(_image){
                const _ratio = ratioFromImage(_image)
                if(_ratio > maxRatio){
                    maxRatio = _ratio
                } 
            }
        })

        return roundTo(maxRatio, 1000)
    }

    onDetailClick(id){
        const {
            dispatch
        } = this.props

        dispatch(openOverlay({type: 'detail', id: id}))
    }

    render() {
        const {
            maxIconRatio
        } = this.state
        const {
            details,
            fixed,
            isActive,
            isLast
        } = this.props

        const TextElComponent = fixed ? FixedTextEl : ScrollTextEl

        return <SectionWrapper
            className="detailsSection"
            naturalHeight={ true }
            fixed={ false }
            isLast={ isLast }>
            <div className="section-content">
                <div className="cont">
                    <div className="sectionSpacer sectionSpacer--double" />
                    <TextElComponent
                        isActive={ isActive }
                        titleEl="h3"
                        title="The details" />
                    <div className="sectionSpacer" />
                    { details && details.length > 0 ? <div className="detailsSection-grid">
                        { details.map((_detail, _i) => {
                            const _id = get(_detail, 'node.wordpress_id')
                            const _title = get(_detail, 'node.title')
                            const _label = get(_detail, 'node.acf.thumbnail_label')
                            const _image = formatImageForARYImage(get(_detail, 'node.acf.thumbnail_image'))

                            return <DetailSquare
                                key={ _i }
                                id={ _id }
                                title={ _label ? _label : _title }
                                image={ _image }
                                imageRatio={ maxIconRatio }
                                onClick={ this.onDetailClick } />
                        }) }
                    </div> : null }
                    <div className="sectionSpacer sectionSpacer--double" />
                </div>
            </div>
        </SectionWrapper>
    }
}

const ReduxDetails = connect()(Details)

export default (props) => {
    return <StaticQuery
        query={ graphql`
            query {
                details: allWordpressWpDetail {
                    edges {
                        node {
                            wordpress_id
                            title
                            acf {
                                thumbnail_label
                                thumbnail_image {
                                    ...ARYImageThumbnail
                                }
                            }
                        }
                    }
                }
            }
        ` }
        render={ (data) => {
            const details = get(data, 'details.edges')

            return <ReduxDetails
                data={ data }
                details={ details }
                { ...props } />
        } } />
}
